import { EyeOutlined } from "@ant-design/icons";
import { Button, Image } from "antd";
import { ReactNode } from "react";
import { MenuNode } from "../../components/MenuComponent";
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import { addFilter } from "../../services/HooksManager";
import { errorToast, successToast } from "../../services/NotificationManager";
import { call, CallRequestConfig } from "../../shared/api";
import ParentModule from "../../shared/module/CrudModule";
import { navigate } from "../../shared/router";
import { __ } from "../../translations/i18n";
import DateUtils from "../../utils/DateUtils";
import WarrantyAdd from "../components/warranty/WarrantyAdd";
import DWPrewarrantyDataProviderImp from "../providers/implementation/DWPrewarrantyDataProviderImp";
import BuEnvironmentManager from "../services/BuEnvironmentManager";
import buUserManager from "../services/BuUserManager";
import { __b } from "../translations/i18n";
import ObjectUtils from "../utils/ObjectsUtil";

class WarrantyModule extends ParentModule {
  buEnvironmentManager: any;

  init = () => {
    this.buEnvironmentManager = BuEnvironmentManager;
    addFilter("warranties_table_search_form", this.addFieldsTosearch, 50);
    addFilter("warranties_table_columns", this.addToTableColumns, 50);
    addFilter("warranties_table_data_row", this.addDataToTableRow, 50);
    addFilter("crud_create_component_warranties", this.getAddComponent);
    addFilter("warranties_get_list_map", this.modifyListResponse);
    addFilter("app_sub_menu", this.warrantySubMenu);
    addFilter("crud_tabs_warranties", this.modifyTabs);
    addFilter("app_routes", this.addTabs);
  };

  getModuleKey = (): string => {
    return "bu-warranties";
  };

  modifyListResponse = async (res: any) => {
    // modify response of listing call
    for await (const el of res) {
      const provider = DWEnvironmentManager.getDataProvider();
      const detail = await provider.addOne(
        { data: [el.item.upc] },
        "masterdata/find"
      );
      el.detail = detail;
    }

    return res;
  };

  warrantySubMenu = (menu: MenuNode[]) => {
    let indexWarranty = menu.findIndex((m) => m.key === "/warranties");
    menu[indexWarranty].label = <i>{__b("warranties.title")}</i>;
    return menu;
  };

  addFieldsTosearch = (fields: any[], module: any) => {
    // removed override fields
    fields[0]["fields"].splice(2, 5);

    fields[0]["fields"].push(
      {
        name: "sequential",
        label: __b("warranties.fields.sequentialCard"),
        type: "text",
        required: false,
        colConf: {
          span: 4,
        },
      },
      {
        name: "itemUpc",
        label: __b("warranties.fields.sapCode"),
        type: "text",
        required: false,
        colConf: {
          span: 4,
        },
      },
      {
        name: "itemSerialNumber",
        label: __b("warranties.fields.serialNumber"),
        type: "text",
        required: false,
        colConf: {
          span: 10,
        },
      },
      {
        name: "returned",
        label: __b("warranties.fields.reso"),
        type: "select",
        required: false,
        selectValue: [
          { label: __b("warranties.fields.values.all"), value: "" },
          { label: __b("warranties.fields.values.reso"), value: "true" },
          { label: __b("warranties.fields.values.noReso"), value: "false" },
        ],
        colConf: {
          span: 8,
        },
      },
      {
        name: "externalCode",
        label: __b("warranties.fields.storeType"),
        type: "select",
        required: false,
        selectValue: [
          { label: __b("warranties.fields.values.all"), value: "" },
          { label: "Ownership", value: "ownership" },
          { label: "Wholesale", value: "wholesale" },
          { label: "Travel Retail", value: "travel retail" },
          { label: "Franchisee", value: "franchisee" },
          { label: "Service Center", value: "service center" },
          { label: "Family and Friends", value: "family and friends" },
          { label: "Vintage", value: "vintage" },
          { label: "Online", value: "online" },
        ],
        colConf: {
          span: 8,
        },
      },
      {
        name: "MatType",
        label: __b("warranties.fields.materialType"),
        type: "select",
        required: false,
        selectValue: [
          { label: __b("warranties.fields.values.all"), value: "all" },
          { label: __b("warranties.fields.values.accessories"), value: "PFMA" },
          { label: __b("warranties.fields.values.watches"), value: "PFMO" },
          { label: __b("warranties.fields.values.jewels"), value: "FINP" },
          { label: __b("warranties.fields.values.fineJewelry"), value: "BCI" },
        ],
        colConf: { span: 8 },
      },
      {
        name: "store",
        label: __b("warranties.fields.nameCodeStore"),
        type: "autocompleteselect",
        required: false,
        mode: "multiple",
        fetchOptions: (key: string) => this.fetchStores(key),
        colConf: {
          span: 8,
        },
      },
      {
        name: "hierarchy",
        label: __b("warranties.fields.operatingCountry"),
        type: "autocompleteselect",
        required: false,
        mode: "multiple",
        fetchOptions: (key: string) => this.fetchHierarchies(key),
        colConf: {
          span: 8,
        },
      },
      {
        name: "activationSource",
        label: __b("warranties.fields.activationType"),
        type: "select",
        required: false,
        selectValue: [
          { label: __b("warranties.fields.values.all"), value: "" },
          { label: "Tag", value: "TAG" },
          { label: __b("warranties.fields.values.manuale"), value: "MANUAL" },
          { label: "Backoffice", value: "BACKOFFICE" },
          {
            label: __b("warranties.fields.values.appManual"),
            value: "MANUAL_APP",
          },
          { label: "Tag APP", value: "TAG_APP" },
          { label: "NO Encoding", value: "NO_ENCODING" },
          { label: "NO Encoding APP", value: "NO_ENCODING_APP" },
        ],
        colConf: {
          span: 4,
        },
      },
      {
        name: "isBehalf",
        label: __b("warranties.fields.onBehalfOf"),
        type: "select",
        required: false,
        selectValue: [
          { label: __b("warranties.fields.values.all"), value: "" },
          { label: __b("warranties.fields.values.yes"), value: "true" },
          { label: __b("warranties.fields.values.no"), value: "false" },
        ],
        colConf: {
          span: 4,
        },
      },
      {
        name: "masterCode",
        label: __b("warranties.fields.masterCode"),
        type: "autocompleteselect",
        required: false,
        mode: "multiple",
        fetchOptions: (key: string) => this.fetchMasterCode(key),
        colConf: {
          span: 8,
        },
      },
      {
        name: "soldToParty",
        label: __b("warranties.fields.soldToParty"),
        type: "autocompleteselect",
        required: false,
        mode: "multiple",
        fetchOptions: (key: string) => this.fetchSoldToParty(key),
        colConf: {
          span: 8,
        },
      },
      {
        name: "responsibilityArea",
        label: __b("warranties.fields.responsibilityArea"),
        type: "autocompleteselect",
        required: false,
        mode: "multiple",
        fetchOptions: (key: string) => this.fetchResponsibilityareas(key),
        colConf: {
          span: 8,
        },
      }
    );

    return fields;
  };

  addToTableColumns = (cols: any[]) => {
    cols = [
      {
        title: __b("warranties.columns.photo"),
        dataIndex: "photo",
        key: "photo",
      },
      {
        title: __b("warranties.columns.cardSequential"),
        dataIndex: "cardSequential",
        sorter: true,
        key: "cardSequential",
      },
      {
        title: __b("warranties.columns.sap"),
        dataIndex: "sap",
        sorter: true,
        key: "sap",
      },
      {
        title: __b("warranties.columns.serialNumber"),
        dataIndex: "serialNumber",
        key: "serialNumber",
      },
      {
        title: __b("warranties.columns.description"),
        dataIndex: "description",
        key: "description",
      },
      {
        title: __b("warranties.columns.activation"),
        dataIndex: "activation",
        sorter: true,
        key: "activation",
      },
      {
        title: __b("warranties.columns.expiredDate"),
        dataIndex: "expiredDate",
        key: "expiredDate",
      },
      {
        title: __b("warranties.columns.activationType"),
        dataIndex: "activationType",
        key: "activationType",
      },
      {
        title: __b("warranties.columns.activationUser"),
        dataIndex: "activationUser",
        key: "activationUser",
      },
      {
        title: __b("warranties.columns.return"),
        dataIndex: "returnDate",
        sorter: true,
        key: "returnDate",
      },
      {
        title: __b("warranties.columns.returnUser"),
        dataIndex: "returnUser",
        key: "returnUser",
      },
      // {
      //     title: __b('warranties.columns.storeUser'),
      //     dataIndex: 'storeUser',
      //     key: 'storeUser'
      // },
      {
        title: __b("warranties.columns.substitution"),
        dataIndex: "substitution",
        key: "substitution",
      },

      {
        title: __b("warranties.columns.details"),
        dataIndex: "id",
        key: "id",
        render: (id: string | number) => (
          <Button
            onClick={() => navigate("/warranties/" + id)}
            icon={<EyeOutlined />}
            size={"small"}
            shape={"circle"}
            type="primary"
          />
        ),
      },
    ];

    return cols;
  };

  addDataToTableRow = (row: any, data: any) => {
    row.store_user_act = `${data.creationStore.code} - ${data.creationStore.description}(${data.creationStore.externalCode})`;

    if (data?.returnStore) {
      row.return_user_act = `${data.returnStore.code} - ${data.returnStore.description}(${data.returnStore.externalCode})`;
    }
    const filteredOps =
      data.operations?.filter((o: any) => o.type === "SUBSTITUTION") || [];
    const substitution = filteredOps.length > 0 ? filteredOps.length : "";
    const hJ =
      data?.detail[row.sku_upc]?.attributes?.MatType?.value === "BCI"
        ? true
        : false;
    return {
      photo: (
        <Image
          fallback={DWEnvironmentManager.getNotFoundImg()}
          src={this.getSrcImageListing(row, data)}
          alt="images"
        />
      ),
      cardSequential: data?.warrantyBadge?.sequential,
      sap: row.sku_upc,
      serialNumber: hJ ? "" : row.serial_number,
      description: data?.detail[row.sku_upc]?.description ?? "-",
      activation: DateUtils.formatDate(data?.creationDate),
      expiredDate: DateUtils.formatDate(data?.expirationDate),
      returnDate: data?.returnDate
        ? DateUtils.formatDate(data?.returnDate)
        : "",
      returnUser: data?.returnedBy ? (
        <div>
          <strong>{row.return_user_act}</strong>
          <div>
            {data?.returnedBy?.name} {data?.returnedBy?.surname}
          </div>
        </div>
      ) : (
        ""
      ),
      activationType: data.activationSource,
      substitution,
      activationUser: (
        <div>
          <strong>
            {data.behalf ? __b("warranties.fields.onBehalfOf") : ""}{" "}
            {row.store_user_act}
          </strong>
          <div>
            {data?.createdBy?.name} {data?.createdBy?.surname}
          </div>
        </div>
      ),
      id: data.id,
    };
  };

  getSrcImageListing = (row: any, data: any) => {
    if (data?.attributes?.image_thumb?.value) {
      return `${DWEnvironmentManager.getStoragePath()}${
        data?.attributes?.image_thumb?.value
      }`;
    }

    return `${DWEnvironmentManager.getStoragePath()}assets/images/imported/${
      row.sku_upc
    }.jpg`;
  };

  fetchHierarchies = async (key: string) => {
    return await fetchHierarchies(key);
  };

  fetchStores = async (key: string) => {
    return await fetchStores(key);
  };

  fetchMasterCode = async (key: string) => {
    return await fetchMasterCode(key);
  };

  fetchSoldToParty = async (key: string) => {
    return await fetchSoldToParty(key);
  };

  fetchResponsibilityareas = async (key: string) => {
    return await fetchResponsibilityareas(key);
  };

  getAddComponent = (component: ReactNode, entity: any) => {
    return (
      <WarrantyAdd
        module={this.getModuleKey()}
        add={this.addWarranty}
        checkSerial={this.checkSerial}
      />
    );
  };

  checkSerial = async (data: any) => {
    const checkSerial = await DWPrewarrantyDataProviderImp.checkSerial(
      {
        params: {
          sap: data.sapCode,
          serial: data.serialNumber,
        },
      },
      "serialnumber/check"
    );
    if (checkSerial === null || !checkSerial.value) {
      return false;
    } else {
      return true;
    }
  };

  addWarranty = async (data: any, selectedStore: any) => {
    if (!data.serialNumber) {
      data.serialNumber = data.sapCode;
    }

    data.item = {
      epc: data.sapCode + data.serialNumber,
      type: "WarrantyItemDto",
      serialNumber: data.serialNumber,
      upc: data.sapCode,
    };
    data.warrantyBadge = { sequential: data.sequential };
    data.activationDate = data.activationDate.valueOf();

    const provider = DWEnvironmentManager.getDataProvider();

    // recuperare zona di sold
    let zoneSold = await this.getZoneSold(data);

    if (zoneSold.length === 0) {
      // create zone SOLD
      zoneSold = await this.createZoneSold(selectedStore);
    }

    // trasferimento dell'item verso la zona di sold - kernel.api
    await this.transferToZoneItem(data, zoneSold[0]);

    // attivazione (con meno campi del previsto)
    const userId = data.userId;
    delete data.sequential;
    delete data.sapCode;
    delete data.serialNumber;
    delete data.storeId;
    delete data.userId;
    delete data.addWarranty;

    const req = {
      data: Object.assign({}, data),
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        user: userId,
      },
    };
    const res: any = await provider.addOne(req, "warranties/admin/create");

    if (res.id) {
      successToast(__("common.success"), __b("warranties.add.success"));
      navigate(`/warranties/${res.id}`);
    } else {
      errorToast(__("error.error"), res);
    }

    // verifica estendibilità garanzia -> a cosa serve?
  };

  getZoneSold = async (data: any) => {
    const baseUrl = DWEnvironmentManager.getStoragePath();
    const method = "GET";
    const path = "/tmr-api/kernel.api/zones";
    const request: CallRequestConfig<any> = {
      params: {
        storeId: data.storeId,
        zoneType: "SOLD",
      },
      baseURL: baseUrl,
    };
    return await call<any[]>(method, path, request);
  };

  createZoneSold = async (selectedStore: any) => {
    const baseUrl = DWEnvironmentManager.getStoragePath();
    const method = "POST";
    const path = "/tmr-api/kernel.api/zones";
    const request: CallRequestConfig<any> = {
      data: {
        store: selectedStore,
        code: `${selectedStore}-sold`,
        zoneType: "SOLD",
      },
      baseURL: baseUrl,
    };
    return await call<any[]>(method, path, request);
  };

  transferToZoneItem = async (data: any, zoneSold: any) => {
    const baseUrl = DWEnvironmentManager.getStoragePath();
    const method = "POST";
    const path = `/tmr-api/kernel.api/zones/${zoneSold.id}/transfer`;
    const request: CallRequestConfig<any> = {
      data: {
        items: [data.item],
        type: "TransferToZone",
      },
      baseURL: baseUrl,
    };
    return await call<any[]>(method, path, request);
  };

  modifyTabs = (tabsItems: any) => {
    const tabs = tabsItems;
    if (!buUserManager.userCanAddWarranties()) {
      tabs.pop();
    }

    return tabs;
  };

  addTabs = (route: any) => {
    const newRoute = route;
    newRoute["/warranties"].props.content.props.tabs.push("create");
    return newRoute;
  };
}

export const fetchHierarchies = async (key: string) => {
  const provider = DWEnvironmentManager.getDataProvider();

  return provider
    .getList(
      20,
      0,
      {
        params: {
          description: key,
          orderBy: "DESCRIPTION",
        },
      },
      "hierarchies"
    )
    .then((response) =>
      response.map((res: any) => ({
        label: res.description,
        value: res.code,
      }))
    );
};

export const fetchStores = async (key: string) => {
  const provider = DWEnvironmentManager.getDataProvider();
  const moduleManager = DWEnvironmentManager.getModuleManager();
  const storeModule = moduleManager.getModule("stores");

  return provider
    .getList(
      20,
      0,
      {
        params: {
          description: key,
          orderBy: "DESCRIPTION",
        },
      },
      storeModule.getModuleKey()
    )
    .then((response) =>
      response.map((res: any) => ({
        label: `${res.code} - ${res.description}`,
        value: `${res.code}-${res.description}`,
      }))
    );
};

export const fetchMasterCode = async (key: string) => {
  const provider = DWEnvironmentManager.getDataProvider();

  return provider
    .getList(20, 0, {}, `stores/mastercodes/search?description=${key}`)
    .then((response) => ObjectUtils.stringIncludes(response, key));
};

export const fetchSoldToParty = async (key: string) => {
  const provider = DWEnvironmentManager.getDataProvider();

  return provider
    .getList(20, 0, {}, `stores/soldtoparties/search?description=${key}`)
    .then((response) => ObjectUtils.stringIncludes(response, key));
};

export const fetchResponsibilityareas = async (key: string) => {
  const provider = DWEnvironmentManager.getDataProvider();

  return provider
    .getList(20, 0, {}, `stores/responsibilityareas/search?description=${key}`)
    .then((response) => ObjectUtils.stringIncludes(response, key));
};

const warrantyModule = new WarrantyModule();
export default warrantyModule;
